import StorageService from '@/services/storage.service'
import storageKeys from '@/constants/storageKeys'
import { defaultFilters } from '@/models/OfferPartners/helpers'
import offerPartnersRepository from '@/repository/generatedRepository/offerPartnersRepository'
import TableOptions from '@/models/TableOptions'
import { omitEmptyDeepFilters, prepareFilters } from '@/helpers/filters'
import partnersEventRepository from "@/repository/generatedRepository/partnersEventRepository";
import partnerStreamRepository from "@/repository/generatedRepository/partnerStreamRepository";
import PartnerGroupRepository from "@/repository/generatedRepository/partnerGroupRepository";
import offerPartnerLandingRepository from "@/repository/generatedRepository/offerPartnerLandingRepository";
import offerPartnerCostHistoryRepository from "@/repository/generatedRepository/offerPartnerCostHistoryRepository";
import offerPartnerRegistrationDaysHistoryRepository
  from "@/repository/generatedRepository/offerPartnerRegistrationDaysHistoryRepository";
import offerPartnerBaselineDepositHistoryRepository
  from "@/repository/generatedRepository/BaselineDepositHistoryRepository";
import offerPartnerBaselinePeriodHistoryRepository
  from "@/repository/generatedRepository/BaselinePeriodHistoryRepository";

const state = {
  list: [],
  filters: StorageService.get(storageKeys.OFFER_PARTNERS_FILTERS) || { ...defaultFilters },
  tableOptions: TableOptions.defaultTableOptions(),
}

const getters = {
  filters: state => state.filters,
  tableOptions: state => state.tableOptions,
  list: state => state.list,
  isOpenFilterPanel: state =>
    state.filters.clientOptions && state.filters.clientOptions.filterPanelExpanded,
}

const actions = {
  async loadData(context, payload) {
    const {signal, ...filters} = payload
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.OFFER_PARTNERS_FILTERS, filters)

    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const appliedCfFilters = omitEmptyDeepFilters(filters.cfFilter)

    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
      cfFilter: appliedCfFilters,
      signal
    })

    const { data } = await offerPartnersRepository.list(preparedFilters)

    context.commit('setList', { data, filters })

    return { products: data.items, pagination: data.pagination }
  },
  async searchOfferPartners(context, filters) {
    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
    })

    const { data } = await offerPartnersRepository.list(preparedFilters)

    return { products: data.items, pagination: data.pagination }
  },
  async loadItem(context, id) {
    const { data } = await offerPartnersRepository.get(id)
    return data
  },
  createItem(context, payload) {
    return offerPartnersRepository.post(payload)
  },
  updateItem(context, payload) {
    const { id, ...data } = payload
    return offerPartnersRepository.put(id, data)
  },
  deleteItem(context, payload) {
    return offerPartnersRepository.delete(payload)
  },
  filtersStorageReset() {
    StorageService.remove(storageKeys.OFFER_PARTNERS_FILTERS)
  },
  updateFiltersClientOptions({ commit, getters }, clientOptions) {
    const updatedFilters = {
      ...getters.filters,
      clientOptions,
    }
    commit('setFilters', updatedFilters)
    StorageService.set(storageKeys.OFFER_PARTNERS_FILTERS, updatedFilters)
  },
  async loadPartnerEvent(context, filters) {
    const { data } = await partnersEventRepository.list(filters)
    return data
  },
  createPartnerEvent(context, payload) {
    return partnersEventRepository.createEventGroup(payload)
  },
  updatePartnerEvent(context, payload) {
    return partnersEventRepository.updateEventGroup(payload)
  },
  deletePartnerEvent(context, payload) {
    return partnersEventRepository.delete(payload)
  },
  async loadPartnerStream(context, filters) {
    const { data } = await partnerStreamRepository.list(filters)
    return data
  },
  async searchPartnerStream(context, filters) {
    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
    })

    const { data } = await partnerStreamRepository.list(preparedFilters)

    return { products: data.items, pagination: data.pagination }
  },
  createPartnerStream(context, payload) {
    return partnerStreamRepository.post(payload)
  },
  updatePartnerStream(context, payload) {
    return partnerStreamRepository.updatePartnerStream(payload)
  },
  deletePartnerStream(context, payload) {
    return partnerStreamRepository.delete(payload)
  },
  async loadPartnerGroup(context, filters) {
    const { data } = await PartnerGroupRepository.list(filters)
    return data
  },
  createPartnerGroup(context, payload) {
    return PartnerGroupRepository.post(payload)
  },
  updatePartnerGroup(context, payload) {
    return PartnerGroupRepository.updatePartnerGroup(payload)
  },
  parsePartnerGroup(context, payload) {
    return PartnerGroupRepository.updatePartnerGroupManual(payload)
  },
  deletePartnerGroup(context, payload) {
    return PartnerGroupRepository.delete(payload)
  },
  createOfferPartnerLanding(context, payload) {
    return offerPartnerLandingRepository.post(payload)
  },
  updateOfferPartnerLanding(context, payload) {
    return offerPartnerLandingRepository.updateLanding(payload)
  },
  deleteOfferPartnerLanding(context, payload) {
    return offerPartnerLandingRepository.delete(payload)
  },
  createCostHistory(context, payload) {
    return offerPartnerCostHistoryRepository.post(payload)
  },
  updateCostHistory(context, payload) {
    return offerPartnersRepository.updateCostHistory(payload)
  },
  deleteCostHistory(context, payload) {
    return offerPartnerCostHistoryRepository.delete(payload)
  },
  createRegistrationDaysHistory(context, payload) {
    return offerPartnerRegistrationDaysHistoryRepository.post(payload)
  },
  updateRegistrationDaysHistory(context, payload) {
    return offerPartnerRegistrationDaysHistoryRepository.updateRegistrationDaysHistory(payload)
  },
  deleteRegistrationDaysHistory(context, payload) {
    return offerPartnerRegistrationDaysHistoryRepository.delete(payload)
  },
  createBaselineDepositHistory(context, payload) {
    return offerPartnerBaselineDepositHistoryRepository.post(payload)
  },
  updateBaselineDepositHistory(context, payload) {
    return offerPartnerBaselineDepositHistoryRepository.updateBlDepositHistory(payload)
  },
  deleteBaselineDepositHistory(context, payload) {
    return offerPartnerBaselineDepositHistoryRepository.delete(payload)
  },
  createBaselinePeriodHistory(context, payload) {
    return offerPartnerBaselinePeriodHistoryRepository.post(payload)
  },
  updateBaselinePeriodHistory(context, payload) {
    return offerPartnerBaselinePeriodHistoryRepository.updateBlPeriodHistory(payload)
  },
  deleteBaselinePeriodHistory(context, payload) {
    return offerPartnerBaselinePeriodHistoryRepository.delete(payload)
  },
}

const mutations = {
  setList(state, { data, filters }) {
    state.list = [...data.items]
    state.tableOptions = new TableOptions(data.pagination, filters)
  },
  setFilters(state, filters) {
    state.filters = { ...filters }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
