/*
  Danger! Do not remove any comments from this file, generator will be broken!
 */

export const locale = {
  // __GENERATED_TRANSLATIONS__
  costsReport: {
     listTitle: 'Costs report',
     editFormTitle: 'Update traffic source',
     createFormTitle: 'Create traffic source',
     formFieldsBlockTitle: 'Main',
     formCreateUpdateSubmitErrorMessage: 'Request error',
     menuItemName: 'Costs report',
     table: {
       id: 'ID',
       title: 'Title',
       active: 'Status',
       actions: 'Actions',
     },
     field: {
       name: 'Title*',
       active: 'Status',
     },
  },
  offerPartners: {
     listTitle: 'Offer-Partner links',
     editFormTitle: 'Update offer partner',
     createFormTitle: 'Create offer partner',
     formFieldsBlockTitle: 'Main',
     formCreateUpdateSubmitErrorMessage: 'Request error',
     uuidSearch: 'UUID search',
     menuItemName: 'Offer-Partner links',
     table: {
       id: 'ID',
       offer: 'Offer',
       partner: 'Partner',
       advertiser: 'Advertiser',
       country: 'Country',
       product: 'Product',
       stream: 'Stream',
       trafficSource: 'Traffic source',
       promoCodes: 'Promo Codes',
       postback: 'Postback',
       paymentModel: 'Payment Model',
       currency: 'Currency',
       cost: 'Cost',
       uuid: 'UUID',
       baseline: 'Baseline',
       baselinePeriod: 'Baseline period, days',
       maxDays: 'Max days from registr.',
       offerTags: 'Offer tags',
       status: 'Status',
       partnerLink: 'Partner link',
       advertiserLink: 'Advertiser link',
       landing: 'Landing',
       subId: 'Sub ID',
       actions: 'Actions',
     },
     field: {
       name: 'Title*',
       active: 'Status',
     },
  },
  spendsParsingLog: {
    listTitle: 'Spends\' parsing logs',
    editFormTitle: 'Update traffic source',
    createFormTitle: 'Create traffic source',
    formFieldsBlockTitle: 'Main',
    formCreateUpdateSubmitErrorMessage: 'Request error',
    menuItemName: 'Spends\' parsing logs',
    success: 'Success',
    warnings: 'Warnings',
    modalDesc: 'There were issues during the parsing process:',
    table: {
      id: 'ID',
      partner: 'Partner',
      group: 'Group',
      fileId: 'File ID',
      date: 'Spends month and date',
      parse: 'Parsing date & time, UTC',
      status: 'Status',
      actions: 'Actions',
    },
    field: {
      name: 'Title*',
      active: 'Status',
    },
  },

  cohortAnalysis: {
    listTitle: 'Cohort analysis',
    editFormTitle: 'Update',
    createFormTitle: 'Create',
    formFieldsBlockTitle: 'Main',
    formCreateUpdateSubmitErrorMessage: 'Request error',
    menuItemName: 'Cohort analysis',
    select: {
      firstDeposit: 'First deposit',
      firstDeposits: 'First deposits',
      regAndDep: 'Registration & Deposit',
      registration: 'Registration'
    },
    filters: {
      targetDate: 'Target date',
      playersCohort: 'Players cohort',
    },
    table: {
      advertiser: 'Advertiser',
      partner: 'Partner',
      product: 'Product',
      country: 'Country',
      offer: 'Offer',
      siteId: 'Site ID',
      subId: 'Sub ID',
      clickId: 'Click ID',
      playerId: 'Player ID',
      landing: 'Landing',
      notUsedSpend: 'Spend without traffic',
      landingNotSelected: '<Landing not selected>',
      click_idNotSelected: '',
      traffic: 'Traffic |source',
      traffic_source: 'Traffic |source',
      trafficNotSelected: '<Traffic source not selected>',
      notSelected: 'Not selected',
      date: 'Period',
      actualCountry: 'Actual |country',
      registrations: 'New |reg',
      newDeposits: 'New deps,|{range}',
      allDeposits: 'All |deposits',
      ratio: 'Reg/Dep |ratio',
      deposits: 'Amount of all dep,|{range}',
      amount_newDeposits: 'Amount of |new dep',
      firstDepAmount: 'First dep |amount',
      amount_bets: 'Amount of bets,|{range}',
      avg_check: 'Average check |{range}',
      income: 'Income,|{range}',
      spend: 'Spend,|{range}',
      roi: 'ROI,|{range}',
      cfd: 'cFD,|{range}',
      actions: 'Actions',
    },
    tableTitle: {
      offer: 'Offers',
      partner: 'Partners',
      advertiser: 'Advertisers',
      click_id: 'Player IDs',
      site_id: 'Site IDs',
      date: 'Days',
      country: 'Countries',
      product: 'Products',
      landing: 'Landings',
      traffic_source: 'Traffic sources',
      playersQty: 'Players quantity:'
    }
  },
  partnersSpendsTable: {
    listTitle: 'Partners\' spends (table)',
    editFormTitle: 'Update partners spends',
    createFormTitle: 'Create partners spends',
    formFieldsBlockTitle: 'Main',
    formCreateUpdateSubmitErrorMessage: 'Request error',
    menuItemName: 'Partners\' spends (table)',
    table: {
      advertiser: 'Advertiser',
      stream: 'Stream',
      offer: 'Offer',
      site_id: 'Site ID',
      country: 'Country',
      actions: 'Actions',
      total: 'Total',
    },
    field: {
      name: 'Title*',
      active: 'Status',
    },
  },
  trafficSources: {
    listTitle: 'Traffic sources',
    editFormTitle: 'Update traffic source',
    createFormTitle: 'Create traffic source',
    formFieldsBlockTitle: 'Main',
    formCreateUpdateSubmitErrorMessage: 'Request error',
    menuItemName: 'Traffic sources',
    table: {
      id: 'ID',
      title: 'Title',
      active: 'Status',
      actions: 'Actions',
    },
    field: {
      name: 'Title*',
      active: 'Status',
    },
  },

  playersReportsApi: {
    listTitle: 'Players reports (API)',
    editFormTitle: 'Players report (API)',
    createFormTitle: 'Create player',
    menuItemName: 'Players reports (API)',
    apiManualRequest: 'API manual request',
    selectDate: 'Select report date.',
    reportDate: 'Report date',
    table: {
      reportDate: 'Report date',
      warnings: 'Warnings',
      requestFile: 'Warnings log file',
      requestedSiteIds: 'Request',
      response: 'Original response',
    }
  },
  landings: {
    listTitle: 'Landings',
    editFormTitle: 'Update landing',
    createFormTitle: 'Create landing',
    formFieldsBlockTitle: 'Main',
    formCreateUpdateSubmitErrorMessage: 'Request error',
    menuItemName: 'Landings',
    table: {
      id: 'ID',
      title: 'Title',
      value: 'Value',
      subId: 'Sub ID',
      active: 'Status',
      actions: 'Actions',
    },
    field: {
      title: 'Title*',
      value: 'Value',
      subId: 'Sub ID',
      active: 'Status',
    },
  },

  budgetReport: {
    listTitle: 'Budget plan',
    editFormTitle: 'Update postback',
    createFormTitle: 'Create postback',
    formFieldsBlockTitle: 'Main',
    formCreateUpdateSubmitErrorMessage: 'Request error',
    menuItemName: 'Budget plan',
    table: {
      title: 'Advertiser & Country',
      budget: 'Planned budget',
      actual_budget: 'Actual budget',
      free_budget: 'Free budget',
      actions: 'Actions',
    },
    field: {
      name: 'Name*',
      placeholder: 'Sum*',
      active: 'Status',
    },
  },

  partnerReport: {
    listTitle: 'Partners report',
    listSubtitle: 'Partners statistics in other partner groups',
    formFieldsBlockTitle: 'Main',
    formCreateUpdateSubmitErrorMessage: 'Request error',
    menuItemName: 'Partners report',
    table: {
      title: 'Partners and groups',
      offer: 'Offer',
      total: 'New dep',
      actions: 'Actions',
    },
    field: {
      name: 'Name*',
      placeholder: 'Sum*',
      active: 'Status',
    },
  },

  roles: {
    listTitle: 'Roles',
    editFormTitle: 'Update role',
    createFormTitle: 'Create role',
    formFieldsBlockTitle: 'Main',
    formCreateUpdateSubmitErrorMessage: 'Request error',
    menuItemName: 'Roles',
    table: {
      id: 'ID',
      title: 'Title',
      active: 'Status',
      actions: 'Actions',
    },
    field: {
      title: 'Title*',
    },
  },

  permissions: {
    userDesc: 'Setting up an access template for a role',
    descUserForm: 'Setting up an access template for this user',
    viewing: 'Viewing',
    editing: 'Editing',
    adminFullAccess: 'Admin full access',
    groupView: 'Partner groups view',
    managersAccess: 'Managers access',
  },
  partnerGroups: {
    listTitle: 'Partner Groups',
    editFormTitle: 'Update Partner group',
    createFormTitle: 'Create Partner group',
    formFieldsBlockTitle: 'Main',
    formFieldsSecondBlockTitle: 'Group offers',
    formFieldsThirdBlockTitle: 'Group partners',
    formFieldsFourthBlockTitle: 'Group managers',
    formCreateUpdateSubmitErrorMessage: 'Request error',
    menuItemName: 'Partner groups',
    table: {
      id: 'ID',
      title: 'Title',
      leaders: 'Team leaders',
      offers: 'Offers',
      partners: 'Partners',
      managers: 'Managers',
      status: 'Status',
      actions: 'Actions',
    },
    field: {
      title: 'Title*',
      leaders: 'Team leaders',
      active: 'Status',
      teamLeaderForCostReport: 'Team leader for Cost report',
      type: 'Type',
    },
  },

  dashboard: {
    listTitle: 'Dashboard',
    menuItemName: 'Dashboard',
    efficiencyChartTitle: 'Efficiency chart',
    efficiencyChartExportTitle: 'Efficiency_chart',
    chart: {
      title: 'Title',
      newDep: 'New dep',
      amountOfNew: 'Amount of new dep',
      amountOfAll: 'Amount of all dep',
      income: 'Income',
      roi: 'ROI',
      spend: 'Spend',
      svg: 'Download SVG',
      png: 'Download PNG',
      csv: 'Download CSV',
    },
    topPartners: {
      title: 'Top 5 partners',
    },
    topCountries: {
      title: 'Top 5 countries',
    },
    chartPeriods: {
      day: 'Day',
      week: 'Week',
      month: 'Month'
    }
  },
  generalReport: {
    listTitle: 'General report',
    editFormTitle: 'Update',
    createFormTitle: 'Create',
    formFieldsBlockTitle: 'Main',
    formCreateUpdateSubmitErrorMessage: 'Request error',
    menuItemName: 'General report',
    menuItemNameNew: 'General report, v.2',
    datePeriod: 'Data period',
    table: {
      advertiser: 'Advertiser',
      partner: 'Partner',
      partnerGroup: 'Partner group',
      partnerTags: 'Partner |tags',
      offerTags: 'Offer |tags',
      product: 'Product',
      country: 'Country',
      offer: 'Offer',
      siteId: 'Site ID',
      month: 'Month',
      promocode: 'Promocode',
      geo_country: 'Actual country',
      subId: 'Sub ID',
      site_id_sub_id: 'Site ID & Sub ID',
      site_id: 'Site ID',
      sub_id: 'Sub ID',
      player_id: 'Player ID',
      clickId: 'Click ID',
      playerId: 'Player ID',
      landing: 'Landing',
      stream: 'Stream',
      week: 'Week',
      manager: 'Manager',
      paymentModel: 'Payment |model',
      notUsedSpend: 'Spend without traffic',
      landingNotSelected: '<Landing not selected>',
      click_idNotSelected: '',
      traffic: 'Traffic |source',
      trafficNotSelected: '<Traffic source not selected>',
      notSelected: 'Not selected',
      date: 'Period',
      actualCountry: 'Actual |country',
      registrations: 'New |regs',
      newDeposits: 'New |deps',
      ratio: 'Reg/Dep |ratio',
      deposits: 'Amount of |all dep',
      all_deposits: 'All |deposits',
      amount_newDeposits: 'Amount of |new dep',
      firstDepAmount: 'First dep |amount',
      amount_bets: 'Amount |of bets',
      avg_bet: 'Average |bet',
      avg_check: 'Average |check',
      avg_new_check: 'Average |new check',
      income: 'Income',
      spend: 'Spend',
      cfd: 'cFD',
      roi: 'ROI',
      click_count: 'Clicks',
      click_reg: 'Click/Reg |ratio',
      actions: 'Actions',
    },
    field: {
      name: 'Name*',
      placeholder: 'Sum*',
      active: 'Status',
    },
    groupBy: {
      offer: 'Offer',
      partner: 'Partner',
      advertiser: 'Advertiser',
      clickId: 'Player ID',
      siteId: 'Site ID',
      subId: 'Sub ID',
      siteIdSubId: 'Site ID & Sub ID',
      date: 'Day',
      week: 'Week',
      country: 'Country',
      product: 'Product',
      landing: 'Landing',
      month: 'Month',
      promocode: 'Promocode',
      traffic: 'Traffic source',
      stream: 'Stream',
      actualCountry: 'Actual country',
      manager: 'Manager',
      partner_group: 'Partner group',
      off: 'Off'
    },
    select: {
      all: 'All',
      firstDep: 'FTD only',
      deposit: 'Deposits only',
      registration: 'Registrations only',
    },
    baseline: {
      qualified: 'Qualified',
      unqualified: 'Unqualified',
      all: 'All'
    }
  },

  playersReports: {
    listTitle: 'Players reports (File import)',
    editFormTitle: 'Players report (file import)',
    createFormTitle: 'Create player',
    formFieldsBlockTitle: 'General data',
    formFieldsBlockSecondTitle: 'Players data',
    formCreateUpdateSubmitErrorMessage: 'Request error',
    importSuccess: 'Report successfully uploaded',
    mainItemName: 'Reports',
    menuItemName: 'Players reports (File import)',
    import: 'Import report',
    deleteReport: 'Delete report',
    importSettings: 'Import settings',
    shouldUpdate: 'Update registration dates for existing players?',
    table: {
      id: 'ID',
      createDate: 'Created date & time, UTC',
      uploadDate: 'Updated date & time, UTC',
      fileName: 'File name',
      request: 'Requested Site IDs',
      response: 'Original response',
      registrationDate: 'Registration date',
      firstDepositDate: 'First deposit date',
      period: 'Report date',
      warnings: 'Warnings',
      warningsLog: 'Warnings log file',
      deposits: 'Amount of deposits (total {sum})',
      bets: 'Amount of bets (total {sum})',
      income: 'Company income (total {sum})',
      actions: 'Actions',
    },
    field: {
      name: 'Name*',
      placeholder: 'Sum*',
      active: 'Status',
    },
    uploadPlayersInfo: 'The report contains Click IDs that are not present in the DB. Download ',
  },

  players: {
    listTitle: 'Players',
    editFormTitle: 'Player',
    createFormTitle: 'Create player',
    formFieldsBlockTitle: 'Main',
    formFieldsBlockSecondTitle: 'Player postbacks',
    formFieldsBlockThirdTitle: 'Player reports',
    tableDescription: 'This section displays the player\'s financial data retrieved from the API service',
    filterByReportDate: 'Filter by report date',
    formCreateUpdateSubmitErrorMessage: 'Request error',
    menuItemName: 'Players',
    importFile: 'Import report',
    table: {
      id: 'Player inner ID',
      outerId: 'Player outer ID',
      clickId: 'Сlick ID',
      createdAt: 'Created, UTC',
      registrationDate: 'Registration, UTC',
      actualCountry: 'Actual country',
      siteId: 'Site ID',
      promocode: 'Promocode',
      subId: 'Sub ID',
      landing: 'Landing',
      trafficSource: 'Traffic source',
      source: 'Traffic source',
      partner: 'Partner',
      stream: 'Stream',
      advertiser: 'Advertiser',
      mediaId: 'Country & Media ID',
      geoCountry: 'IP',
      offer: 'Offer',
      offerPartnerUUID: 'Offer-Partner UUID',
      firstDepositDate: 'First deposit, UTC',
      firstDeposit: 'First deposit, UTC',
      partnerLink: 'Partner link',
      advLink: 'Advertiser link',
    },
    tablePostback: {
      id: 'ID',
      date: 'Date & time, UTC+6',
      eventType: 'Event type',
      eventValue: 'Event value',
    },
    tableReports: {
      createdAt: 'Created date & time, UTC',
      updatedAt: 'Updated date & time, UTC',
      reportDate: 'Report date',
      newDep: 'Amount of new deposits',
      reportData: 'Report data',
      newDepTooltip: 'Value of "ftd_sum" from Players Report',
      deposits: 'Amount of deposits (total {sum})',
      depositsNoSum: 'Amount of deposits |(total {sum})',
      newDepositsNoSum: 'Amount of new |deposits (total {sum})',
      newDepositsNoSumTooltip: 'Value of "ftd_date" from Players Report',
      bets: 'Amount of bets (total {sum})',
      betsNoSum: 'Amount of bets |(total {sum})',
      betsTooltip: 'Value of "income" from Players report',
      bonusNoSum: 'Bonus |(total {sum})',
      commissionNoSum: 'Commission |(total {sum})',
      totalCommissionNoSum: 'Total commission |(total {sum})',
      income: 'Company income (total {sum})',
      incomeTooltip: 'Value of "net_rev" from Players report',
      incomeNoSum: 'Income (total {sum})',
      firstDepositDateTooltip: 'Value of the first "date" from Player’s Reports',
    },
    field: {
      name: 'Name*',
      placeholder: 'Sum*',
      active: 'Status',
    },
    sourceApi: '<source: API>',
  },

  currencies: {
    listTitle: 'Currencies',
    editFormTitle: 'Update currency',
    createFormTitle: 'Create currency',
    formFieldsBlockTitle: 'Main',
    formCreateUpdateSubmitErrorMessage: 'Request error',
    menuItemName: 'Currencies',
    table: {
      id: 'ID',
      title: 'Title',
      value: 'Value in tag',
      active: 'Status',
      actions: 'Actions',
    },
    field: {
      title: 'Title*',
      value: 'Value in tag (adv. link)',
      active: 'Status',
    },
  },

  postbacks: {
    listTitle: 'Postbacks',
    editFormTitle: 'Update postback',
    createFormTitle: 'Create postback',
    formFieldsBlockTitle: 'Main',
    formCreateUpdateSubmitErrorMessage: 'Request error',
    menuItemName: 'Postbacks',
    resendPostbacks: 'Resend postbacks',
    resendPostback: 'Resend postback',
    table: {
      id: 'ID',
      createdAt: 'Date & Time, UTC+6',
      createdAtKyiv: 'Date & Time, Kyiv time',
      advertiser: 'Advertiser',
      clickId: 'Click ID',
      playerId: 'Player outer ID',
      partnerId: 'Partner ID',
      siteId: 'Site ID',
      landing: 'Landing',
      sum: 'Sum dep',
      country: 'Country & Media ID',
      geoCountry: 'Actual country',
      transaction: 'Transaction',
      other: 'Other',
      offer: 'Offer',
      partner: 'Partner',
      trafficSource: 'Traffic source',
      eventType: 'Event Type',
      eventValue: 'Event Value',
      senderIp: 'Sender IP',
      uuid: 'Offer-Partner UUID',
      url: 'URL',
      postbackPartner: 'Partner',
      partnerPostbackUrl: 'Partner postback URL',
      partnerPostbackMethod: 'Partner postback method',
      partnerPostbackDate: 'Partner postback date & time, UTC',
      response: 'Response',
      error: 'Error',
      actions: 'Actions',
    },
    field: {
      name: 'Name*',
      placeholder: 'Sum*',
      active: 'Status',
    },
  },

  events: {
    listTitle: 'Events',
    editFormTitle: 'Update event',
    createFormTitle: 'Create event',
    formFieldsBlockTitle: 'Main',
    formCreateUpdateSubmitErrorMessage: 'Request error',
    menuItemName: 'Events',
    table: {
      id: 'ID',
      name: 'Title',
      partner: 'Partner',
      placeholder: 'Parameter name',
      active: 'Status',
      actions: 'Actions',
    },
    field: {
      name: 'Name*',
      placeholder: 'Parameter name',
      active: 'Status',
    },
  },

  partnersSpends: {
    listTitle: 'Partners\' spends (pages)',
    editFormTitle: 'Update partner spends',
    createFormTitle: 'Create partner spends',
    formFieldsBlockTitle: 'Main',
    formCreateUpdateSubmitErrorMessage: 'Request error',
    importSuccess: 'File successfully uploaded',
    menuItemName: 'Partners\' spends',
    submenuItemName: 'Partners\' spends (pages)',
    import: 'Import spends',
    table: {
      id: 'ID',
      name: 'Name',
      partner: 'Partner',
      stream: 'Stream',
      siteId: 'Site ID',
      country: 'Country',
      date: 'Date',
      sum: 'Sum (total {sum})',
      sumNoTotal: 'Sum',
      actions: 'Actions',
    },
    field: {
      stream: 'Stream*',
      country: 'Country*',
      sum: 'Sum*',
      date: 'Date*'
    },
  },

  users: {
    listTitle: 'Users',
    editFormTitle: 'User update',
    createFormTitle: 'User сreate',
    formFieldsBlockTitle: 'Main',
    formCreateUpdateSubmitErrorMessage: 'Request error',
    menuItemName: 'Users',
    table: {
      id: 'ID',
      name: 'Full name',
      email: 'Email',
      role: 'Role',
      partners: 'Partners',
      enabled: 'Status',
      actions: 'Actions',
    },
    field: {
      name: 'Full name',
      email: 'Email*',
      password: 'Password*',
      enabled: 'Status',
      role: 'Role',
      nameForCostReport: 'Name for Cost report',
    },
    roles: {
      ADMIN: 'Admin',
      TEAM_LEADER: 'Team leader',
      MANAGER: 'Manager',
      PARTNER: 'Partner',
    }
  },

  clicks: {
    listTitle: 'Clicks',
    editFormTitle: 'CRUD Edit Heading',
    createFormTitle: 'CRUD Create Heading',
    formFieldsBlockTitle: 'Fields for data',
    formCreateUpdateSubmitErrorMessage: 'Request error',
    search: 'Click ID search',
    menuItemName: 'Clicks',
    table: {
      id: 'ID',
      clickId: 'Click ID',
      createdAt: 'Date & time, UTC',
      offerTitle: 'Offer',
      partnerTitle: 'Partner',
      advertiserTitle: 'Advertiser',
      landing: 'Landing',
      partnerLink: 'Partner Link',
      advertiserLink: 'Advertiser Link',
      country: 'Country & MediaID',
      actions: 'Actions',
      IP: 'IP',
      traffic: 'Traffic source'
    },
    field: {
      idOneC: 'ID 1c',
      name: 'Name',
      countries: 'Countries',
      countries2: 'Countries 2',
      scriptName: 'Name for scripts',
    },
  },

  partners: {
    listTitle: 'Partners',
    editFormTitle: 'Update partner',
    createFormTitle: 'Create partner',
    formFieldsBlockTitle: 'Main',
    formFieldsManagersBlockTitle: 'Managers',
    formFieldsManagersBlockSubtitle: 'Managers associated with current partner ',
    formFieldsUsersBlockTitle: 'Partner users',
    formFieldsUsersBlockSubtitle: 'Users with the Partner role who work with the current partner',
    formFieldsStreamBlockTitle: 'Streams',
    formFieldsStreamBlockSubtitle: 'Streams and their associations with partner’s groups',
    formFieldsAffiliateBlockTitle: 'Affiliate IDs',
    formFieldsAffiliateBlockSubtitle: 'Specific partner affiliate IDs for each advertisers',
    partnerGroupsResources: 'Partner groups & Spends’ resources',
    partnerGroupsResourcesDesc: 'Partner groups associated with current partner and the Google Spreadsheet IDs where partner’s spends are stored',
    partnerMainInfo: 'Main data',
    partnerInfo: 'Additional partner data',
    formFieldsFirstBlockTitle: 'Postback URLs',
    formFieldsFirstBlockSubtitle: 'Postback URLs where event postbacks from advertisers should be sent by the system',
    groupRequired: 'To add managers, you must add at least 1 group above',
    formTwoFieldsBlockTitle: 'Offer - Partner links',
    formCreateUpdateSubmitErrorMessage: 'Request error',
    postbackTitle: '{title} postback',
    menuItemName: 'Partners',
    disabledParse: 'Save changes to parse spends',
    customLanding: 'Add custom landing',
    recommendLanding: 'Add default landing(s)',
    modal: {
      parseManually: 'Parse manually',
      period: 'Period',
      date: 'Date',
      day: 'Day',
      month: 'Month'
    },
    table: {
      id: 'ID',
      title: 'Title',
      offerPartners: 'Offers',
      users: 'Partner users',
      active: 'Status',
      actions: 'Actions',
    },
    field: {
      title: 'Title*',
      status: 'Status',
      state: 'State',
      partnerUsers: 'Partner users',
      partnerUser: 'Partner user',
      partnerGroups: 'Partner groups',
      partnerGroup: 'Partner group',
      offer: 'Offer*',
      siteId: 'Site ID',
      siteIdRequired: 'Site ID*',
      subId: 'Sub ID',
      user: 'User logins',
      telegram: 'Telegram contacts',
      offerTags: 'Offer tags',
      email: 'Emails',
      partnerTags: 'Partner tags',
      postback: 'Postback',
      currency: 'Currency',
      currencyRequired: 'Currency*',
      postbackNum: 'Postback {num}',
      postbackUUID: 'Postback UUID',
      postbackUUIDRequired: 'Postback UUID*',
      landing: 'Landing',
      partnerLink: 'Partner Link',
      advertiserLink: 'Advertiser Link',
      postbackUrl: 'Postback URL',
      stream: 'Stream',
      streamRequired: 'Stream*',
      method: 'Method',
      siteName: 'Site name',
      manager: 'Manager',
      linkOffer: 'Link Offer',
      promoCodes: 'Promo Codes',
      baseline: 'Baseline',
      baselinePeriod: 'Baseline period, days',
      maxDaysFromRegistration: 'Max days from registration',
      betRequirement: 'Bet requirement for qualification',
      subIdPlaceholder: 'Sub ID placeholder',
      sendSubId: 'Resend sub_(s) from Partner links',
      sendSubIdPlaceholder: 'Placeholder of the sub_id parameter in the Partner link',
      sheetId: 'Spend’s spreadsheet id',
      affiliateId: 'Affiliate ID',
      teamName: 'Team name'
    },
    mediaIdMissing: 'Media ID or advertiser domain is missing. The system cannot create the link.',
    unapproved: 'The link can not be created because the partner is not approved',
    buttons: {
      logs: 'Parsing logs',
      parseMan: 'Parse spends'
    },
    position: {
      main: 'Main',
      ordinary: 'Ordinary'
    }
  },

  requestMethods: {
    get: 'GET',
    post: 'POST'
  },

  offer: {
    noLandings: 'No default landing(s) to add',
    listTitle: 'Offers',
    editFormTitle: 'Update offer',
    createFormTitle: 'Create offer',
    formFieldsBlockTitle: 'Main',
    formTwoFieldsBlockTitle: 'Offer - Partner links',
    formCreateUpdateSubmitErrorMessage: 'Request error',
    invalidFields: 'Empty required fields in "{tab}" tab',
    menuItemName: 'Offers',
    table: {
      id: 'ID',
      title: 'Title',
      advertiserTitle: 'Advertiser',
      countryTitle: 'Country & Media ID',
      paymentModelTitle: 'Payment Model',
      currency: 'Currency',
      cost: 'Cost',
      productTitle: 'Product',
      status: 'Status',
      state: 'State',
      groups: 'Groups',
      actions: 'Actions',
    },
    field: {
      title: 'Title*',
      advertiser: 'Advertiser',
      country: 'Country & Media ID',
      paymentModel: 'Payment Model',
      cost: 'Cost',
      days: 'Days',
      baseline: 'Baseline',
      stream: 'Stream',
      streamRequired: 'Stream*',
      currency: 'Currency',
      currencyRequired: 'Currency*',
      source: 'Traffic source',
      product: 'Product',
      partner: 'Partner*',
      siteId: 'Site ID',
      subId: 'Sub ID',
      postback: 'Postback',
      landing: 'Landing',
      linkLanding: 'Link landing',
      landings: 'Landings',
      partnerLink: 'Partner Link',
      partnerLinks: 'Partner links',
      advertiserLink: 'Advertiser link: {link}',
      differentLink: 'This link does not match the link in the database. The link in the database: {link}',
      linkPartner: 'Link partner',
      linkOffer: 'Link offer',
      status: 'Status',
      state: 'State',
      generalInfo: 'General info',
    },
    states: {
      approved: 'Approved',
      unapproved: 'Unapproved',
    },
    showLess: 'Show less',
    showMore: 'Show more',
    expandAll: 'Expand offer-partners',
    hideAll: 'Roll up offer-partners',
    costHistory: 'Cost and Baseline history',
    costHistoryDesc: 'This section allows configuration of the cost, baseline values, and their activity periods for the current offer-partner.',
    blPeriodHistory: 'Baseline period history',
    blPeriodHistoryDesc: 'This section allows configuration of how many days after the first deposit players associated with the current offer-partner have to meet the baseline.',
    blDepositHistory: 'Bet requirement for qualification history',
    blDepositHistoryDesc: 'This section enables the configuration of the date range during which players associated with the current offer-partner must place a bet to meet the baseline conditions and qualify.',
    daysRegistrationHistory: '“Max days from registration” history',
    daysRegistrationHistoryDesc: 'This section allows configuration of the number of days after registration that players associated with the current offer-partner have to meet baseline conditions.',
    intersection: 'Data interception issue. Please check ranges above.',
    addCost: 'Add cost',
    add: 'Add',
    unapproved: 'The link can not be created because the offer is not approved'
  },

  country: {
    listTitle: 'Countries',
    editFormTitle: 'Update country',
    createFormTitle: 'Create country',
    formFieldsBlockTitle: 'Main',
    formCreateUpdateSubmitErrorMessage: 'Request error',
    menuItemName: 'Countries',
    active: 'Active',
    inactive: 'Inactive',
    activity: 'Status',
    table: {
      id: 'ID',
      title: 'Title',
      slug: 'Code',
      subId: 'Sub ID',
      currency: 'Currency',
      domain: 'Partner links domain',
      active: 'Status',
      actions: 'Actions',
    },
    field: {
      title: 'Title*',
      slug: 'Code',
      subId: 'Sub ID',
      currency: 'Currency',
      domain: 'Partner links domain',
      status: 'Status',
    },
  },

  paymentModels: {
    listTitle: 'Payment models',
    editFormTitle: 'Update payment model',
    createFormTitle: 'Create payment model',
    formFieldsBlockTitle: 'Main',
    formCreateUpdateSubmitErrorMessage: 'Request error',
    menuItemName: 'Payment models',
    table: {
      id: 'ID',
      title: 'Title',
      cost: 'Cost',
      active: 'Status',
      actions: 'Actions',
    },
    field: {
      title: 'Title*',
      costReportTitle: 'Title for Costs report',
      cost: 'Cost',
      defaultCost: 'Default cost',
      defaultBaseline: 'Default baseline',
      defaultPeriod: 'Default baseline period (days)',
      maxDaysFromRegistration: 'Default max days from registration',
      status: 'Status',
    },
  },

  advertisers: {
    listTitle: 'Advertisers',
    editFormTitle: 'Update advertiser',
    createFormTitle: 'Create advertiser',
    formFieldsBlockTitle: 'Main advertiser data',
    formFieldBlockCountries: 'Countries',
    formFieldBlockCountriesDesc: 'Advertiser\'s countries and their settings for Offer-Partner-Landings and advertiser links.',
    formFieldBlockLandings: 'Default landings',
    formFieldBlockLandingsDesc: 'Landings that can be quickly added to an offer-partner of current advertiser.',
    formCreateUpdateSubmitErrorMessage: 'Request error',
    menuItemName: 'Advertisers',
    tooltipOfferExist: 'The connection cannot be deleted because this country is linked to the following offers: {offers}',
    table: {
      id: 'ID',
      title: 'Title',
      domain: 'Domain',
      active: 'Status',
      actions: 'Actions',
    },
    field: {
      title: 'Title*',
      domain: 'Default domain (Start with https://)',
      status: 'Status',
      mediaId: 'Media ID',
      specificDomain: 'Country specific domain (Start with https://)',
      costBaseline: 'Default cost',
      defaultBaseline: 'Default baseline',
      allowedLandings: 'Allowed landings',
      linkCountry: 'Country',
      addLanding: 'Landing',
    },
  },
  modalSuccessError: {
    close: 'Close',
    success: 'Success',
    error: 'Error',
    successSync: 'Sync completed successfully',
    errorSync: 'Error during sync',
  },
  products: {
    listTitle: 'Products',
    editFormTitle: 'Update product',
    createFormTitle: 'Create product',
    formFieldsBlockTitle: 'Main',
    formCreateUpdateSubmitErrorMessage: 'Request error',
    menuItemName: 'Products',
    table: {
      id: 'ID',
      title: 'Title',
      active: 'Status',
      actions: 'Actions',
    },
    field: {
      title: 'Title*',
      status: 'Status',
    },
  },

  filters: {
    activity: 'Status',
    activityType: 'Activity type',
    partner: 'Partner',
    partners: 'Partners',
    advertiser: 'Advertiser',
    advertiserRequired: 'Advertiser*',
    country: 'Country',
    countries: 'Countries',
    offer: 'Offer',
    paymentModel: 'Payment model',
    partnerTags: 'Partner tags',
    offerTags: 'Offer tags',
    pModel: 'P Model',
    subId: 'Sub ID',
    siteId: 'Site ID',
    mediaId: 'Media ID',
    clickId: 'Click ID',
    product: 'Product',
    products: 'Products',
    productReq: 'Product*',
    productsReq: 'Products*',
    players: 'Players',
    show: 'Show',
    manager: 'Manager',
    partnerGroup: 'Partner group',
    partnerUser: 'Partner user',
    partnerGroups: 'Partner groups',
    partnerGroupsRequired: 'Partner groups*',
    landing: 'Landing',
    landings: 'Landings',
    landingReq: 'Landing*',
    landingsReq: 'Landings*',
    trafficSource: 'Traffic source',
    state: 'State',
    stream: 'Stream',
    baseline: 'Baseline',
    geoCountry: 'Actual country',
    event: 'Event',
    promocode: 'Promocode',
    position: 'Position',
    createdDatePeriod: 'Created date period',
  },

  modalAbsentClickId: {
    title: 'Attention',
    descriptionSuccess: 'File uploaded successfully!',
    descriptionFail: 'File uploaded successfully!',
    notFound: 'The report contains Click IDs that are not present in the DB or are empty. Download ',
    sizeExceed: 'File size exceeded (100 MB)',
    wrongFormat: 'File format is wrong',
    periodAbsent: 'Date period is absent',
    closeBtn: 'Close',
    importNew: 'Import new',
    cancelBtn: 'Cancel'
  },
  settings: {
    settingsTitle: 'Settings',
    settingsGeneral: 'Main',
    dataFilter: 'Data filter',
    spendsParsing: 'Spends parsing',
    dailyParsingTime: 'Spends daily parsing time, UTC',
    monthlyParsingTime: 'Spends monthly parsing time, UTC',
    monthlyParsingDay: 'Spends monthly parsing day',
    dataSelect: 'Partner groups data view',
    dataRetrievalTime: 'Data retrieval time, UTC',
    menuItemName: 'Settings',
    saveSuccess: 'Data successfully updated',
    uploadSuccess: 'Data uploaded successfully!',
    postbackSuccess: 'Postback(s) resent successfully',
    field: {
      domainName: 'Partner link domain',
      postBackUrl: 'URL for postbacks',
      postBackUrlDesc: 'This URL is configured to receive postbacks from other platforms. It supports HTTP GET requests with the following parameters: \'click_id\' and event types such as \'reg\', \'ftd\', and \'bl\'.',
      dataFilterDesc: 'This section enables the admin to globally activate a data filter by Partner Group, restricting access to data based on the selected group. When a Partner Group is chosen, the admin will only see data related to that group.',
      spendsParsingDesc: 'This section configures the time for retrieving agent spend data from Google Spreadsheets.',
      playersReports: 'Players\' reports from API-service',
      playersReportsDesc: 'This section configures the time to retrieve players data from the 3rd-party API service for the previous day.',

    },
    btn: {
      save: 'Save',
    },
  },

  base: {
    save: 'Save',
    submit: 'Submit',
    saveContinue: 'Save & continue',
    download: 'Download',
    cancel: 'Cancel',
    remove: 'Remove',
    delete: 'Delete',
    create: 'Create',
    confirm: 'Confirm',
    areYouSureToDelete: 'Are you sure want to delete?',
    areYouSureToDeactivate: 'Confirm deactivation',
    areYouSureToActivate: 'Confirm activation',
    multiselectSelected: '{count} selected',
    yes: 'Yes',
    no: 'No',
    add: 'Add New',
    edit: 'Edit',
    editing: 'Editing',
    time: 'Time',
    back: 'Back',
    saveBack: 'Save & back',
    filters: 'Filters',
    apply: 'Apply',
    selectAll: 'Select all',
    clearAll: 'Clear all',
    deactivate: 'Deactivate',
    activate: 'Activate',
    listStatusActive: 'Active',
    listStatusInactive: 'Inactive',
    search: 'Search',
    noData: 'No data',
    all: 'All',
    allCount: 'All ({count})',
    onlyChosen: 'Only chosen',
    attention: 'Attention',
    fill: 'Fill',
    column: 'column',
    row: 'row',
    validationError: 'Validation error',
    validationErrorServer: 'validation server error',
    noMoreItems: 'No more items',
    noElementsFound: 'No elements found',
    import: 'Import',
    exportTemplate: 'Export template',
    resetFilters: 'Reset',
    createdAt: 'Created at',
    createdBy: 'Created by',
    actions: 'Actions',
    city: 'City',
    district: 'District',
    region: 'Region',
    country: 'Country',
    today: 'Today',
    yesterday: 'Yesterday',
    currentMonth: 'Current month',
    noDataAvailable: 'No data available',
    startTypingText: 'Start typing text',
    change: 'Change',
    month: 'Month',
    day: 'Day',
    hour: 'Hour',
    minute: 'Minute',
    toTheBegining: 'To the beginning',
    warning: 'Warning',
    choosePeriod: 'Choose period',
    selectPeriod: 'Select period',
    rangeDays: 'Range days',
    filtersActive: 'Active',
    filtersDeactivated: 'Deactivated',
    filtersAll: 'All',
    selectFields: 'Select fields',
    open: 'Open',
    show: 'Show',
    close: 'Close',
    reset: 'Reset',
    operator: 'Operator',
    notOperator: 'Not operator',
    export: 'Export',
    exportReport: 'Export report',
    manualRequest: 'Manual request',
    apiAutoRequests: 'API auto requests current status:',
    images: 'Images',
    product: 'Product',
    selected: 'Selected',
    items: 'items',
    onPage: 'Showing',
    paginationOnPage: 'First group rows qty -',
    paginationFrom: 'from',
    history: 'History',
    field: 'Field',
    success: 'Success',
    comments: 'Comments',
    choose: 'Choose',
    value: 'Value',
    role: 'Role',
    group: 'Group',
    niche: 'Niche',
    total: 'Total',
    from: 'from',
    mobileView: 'For correct display use desktop version',
    goToMain: 'Go to main',
    returnToMainPage: 'Return to main page',
    showNumber: 'Show number',
    failedToLoad: 'Failed to load',
    failedToLoadDescription: 'Error while loading page. Try reload page',
    limitText: 'and {count} more',
    addSelectedOptions: 'Add selected options',
    removeSelectedOptions: 'Remove selected options',
    ok: 'Хорошо',
    selectDate: 'Select date',
    presets: {
      today: 'Today',
      todayAndYesterday: 'Today and yesterday',
      yesterday: 'Yesterday',
      currentWeek: 'Current week',
      previousWeek: 'Previous week',
      currentMonth: 'Current month',
      previousMonth: 'Previous month',
      currentYear: 'Current year',
    },
    beWaiting: 'Wait',
    dataLoading: 'The data is being processed',
    asidePlatformManagement: 'Platform management',
    asideUsersManagement: 'Users management',
    asideReports: 'Reports',
  },

  page: {
    login: 'Login',
    qrCode: 'Two-factor authentication settings',
    pageError403: 'Access forbidden',
    pageError404: 'Page not found',
    restore: 'Forgot password?',
    recoveryPassword: 'Recovery password',
  },
  tooltip: {
    settingsPage: 'Settings page',
    deleteEntity: 'Delete entity',
    deactivateEntity: 'Deactivate entity',
  },

  authLogin: {
    username: 'Username',
    usernameExample: 'name@example.com',
    email: 'Email',
    password: 'Password',
    loginIn: 'Login',
    codeConfirm: 'Proceed',
    invalidCredentials: 'Wrong email or password!',
    invalidCode: 'Invalid or outdated code!',
    forgetPassword: 'Forgot password?',
    welcome: 'Hey, hello ',
    welcomeDesc: 'Enter the information you entered while registering',
    twoFactorModalTitle: 'Two-factor authentication settings',
    twoFactorModalDesc: 'Enter 6-digit code from the Google Authenticator',
    twoFactorTitle: 'Two-factor authentication settings',
    twoFactorDescription:
      'Scan this QR-code with Google Authenticator app. Enter 6-digit key from the app.',
    qrCode: 'Code from app',
    googleCode: 'Google Authenticator code',
    step: 'Step',
    step1SubTitle: 'Download the mobile app',
    step2SubTitle: 'Scan the QR code',
    step3SubTitle: 'Enter authentication code',
    step1SubDescription: 'Download and install Google Authenticator on your phone or tablet',
    step2SubDescription: 'Open the application, click on the “+” in the upper right corner and scan the code using the camera',
    step3SubDescription: 'After scanning the QR code, enter the six-digit code generated by the application',
    errorComparePassword: 'Password does not meet the requirements',
    usedPassword: 'The password was used before',
    errorPassword: 'Invalid password',
    errorLogin: 'Invalid email',
  },
  authRestore: {
    email: 'Email',
    emailExample: 'name@example.com',
    forgetPassword: 'Forgot password?',
    authRestoreDesc: 'Enter your email and get a recovery link:',
    send: 'Send',
    checkYourEmail: 'Check your Email',
    sentDesc: 'Please check the email address',
    sentDesc2: 'for instructions to reset your password.',
    isNotFounded: 'Email not found. Try again!',
    wrongEmail: 'Wrong email?',
    enterAgain: 'Enter again!'
  },
  authRecoveryPassword: {
    createNewPassword: 'Create a password',
    password: 'Password',
    createNewPasswordDesc: 'Type new password and confirm it',
    create: 'Create',
    first: 'New Password',
    second: 'Repeat',
    titleOfDemands: 'Your Password must to consist of:',
    lengthDemand: 'minimum 8 characters',
    capitalDemand: 'one capital letter',
    lowercaseDemand: 'one lowercase letter',
    numberDemand: 'one digit (0-9)',
    symbolDemand: 'one character (!@#$%^&*.)',
    example: 'Example:',
    exampleSymbols: 'Vtlq3*fsdfhqwj*',
    length: '8',
    generatePassword: 'Generate password',
    sameField: 'Passwords must match',
  },
  error: {
    invalid: '{name} is invalid',
    required: '{name} required',
    requiredIf: '{name} required',
    email: 'Wrong Email format',
    maxLength: 'Max length - {length} symbols',
    minLength: 'Min length - {length} symbols',
    maxValue: 'Max value: {value}',
    minValue: 'Min value: {value}',
    onlyNumbers: 'Only numbers',
    onlyIntegerNumbers: 'Only integer numbers',
    onlyIntegerPositiveNumbers: 'Only integer positive numbers',
    onlyWords: 'Only words',
    allow: 'Your password should contain: ',
    sameField: 'Passwords should be the same',
    regexPassword: 'Wrong password format',
    url: 'Wrong URL',
    domainUrl: 'Domain URL must start with "https://"',
    dateFromLess: 'Date "from" should be less than date "to"',
    dateToLess: 'Date "to" should be greater than date "from"',
    commaSpaceRule: 'Start and end of field shouldn\'t contain space or comma',
    lengthDemand: 'Minimum 8 characters',
    capitalDemand: 'Upper case letters (A-Z)',
    lowercaseDemand: 'Lower case letters (a-z)',
    numberDemand: 'Numbers (0-9)',
    symbolDemand: 'Special characters (e.g. !@#$%^&*)',
    latinDemand: 'Password contains only latin letters',
    latinSymbols: 'Cyrillic characters are not allowed',
    invalidValue: 'Wrong value',
    notBeEmpty: 'Value shouldn\'t be empty',
    error403Title: '403!',
    error403Desc: 'Page doesn\'t exists or you don\'t have access.',
    error404Title: '404!',
    error404Desc: 'Something seems to have gone wrong! The page you are requesting does not exist. Maybe it is outdated or deleted.',
    emailExist: 'This email is already registered',
    duplicateEntries: 'Duplicate entries. Already used here: ',
    noData: 'Please use filters to generate the report',
    noDataDesc: 'It looks like there is no data to display at the moment. Please use <br>the filter options to customize and generate your statistics.',
    systemUpdate: 'System Update',
    systemUpdateDesc: 'A new version of the system has been deployed. To ensure optimal performance and to load the latest features, please refresh your page.',
    refreshPage: 'Refresh page',
  },
  lang: {
    ru: 'Russian',
    en: 'English',
  },
}

export default locale
